import React, { useContext, useEffect, useState } from "react";
import "@gdk/card-selections/dist/styles/gdk-card-selections.css";
import { UserIncentiveSummaryContext } from "../../context/userContext";
import "../../styles/ActivePromotions.css";

const ActivePromotions = (props) => {
  const { updateChosenIncentiveId, principalFilter, chosenIncentiveId } = props;
  const incentiveSummary = useContext(UserIncentiveSummaryContext);
  const [cardsToDisplay, setCardsToDisplay] = useState([]);
  const getIconClassName = (lob) => {
    switch (lob.toUpperCase()) {
      case "PERSONALAUTO":
        return "geico-icon icon--primary-blue icon-vehicle";
      case "COMMERCIALAUTO":
        return "geico-icon icon--primary-blue icon-commercial-ins";
      default:
        return "geico-icon icon--primary-blue icon-vehicle";
    }
  };

  const selectDefaultRadioCard = (chosenIncentiveId) => {
    const defaultRadioCard = document.querySelector(".radio-card-" + chosenIncentiveId);
    if (defaultRadioCard) {
      defaultRadioCard.classList.add(["selected"]);
    }
  };

  useEffect(() => {
    if (incentiveSummary != null) {
      if (principalFilter === "Agency") {
        setCardsToDisplay(incentiveSummary.filter(function (data) {return data.isAgencyOnly;}));
      }
      else if (principalFilter === "Producer") {
        setCardsToDisplay(incentiveSummary.filter(function (data) {return !data.isAgencyOnly;}));
      }
      else {
        setCardsToDisplay(incentiveSummary);
      }
    }
  }, [incentiveSummary, principalFilter])

  selectDefaultRadioCard(chosenIncentiveId);

  const selectRadioCard = (cardNumber) => {
    // Loop through all radio cards, and remove the class "selected" from those elements.
    const allRadioCards = document.querySelectorAll(".radio-card");
    allRadioCards.forEach((element) => {
      element.classList.remove(["selected"]);
    });
    // Add the class "selected" to the card which user has clicked on.
    const selectedCard = document.querySelector(".radio-card-" + cardNumber);
    if (selectedCard) {
      selectedCard.classList.add(["selected"]);
      updateChosenIncentiveId(cardNumber);
    }
  };

  // When Home first renders, apply the "selected" CSS class to the card with the default chosenIncentiveId
  useEffect(() => {
    selectDefaultRadioCard(chosenIncentiveId);
    selectRadioCard(chosenIncentiveId);
  }, [chosenIncentiveId]);

  return (
    <>
      {incentiveSummary && incentiveSummary.length > 0 && (
        <div>
          <h4>Active Promotions</h4>
          <div className="card-selection-wrapper" id="card-wrapper">
            <div className="card-selections" id="card-selections-container">
              {cardsToDisplay.map(function (data) {
                return (
                  <div
                    key={data.incentiveId}
                    className={
                      "radio-card card-width radio-card-" +
                      data.incentiveId+ " " + data.incentiveType.toUpperCase()
                    } 
                    id={data.incentiveId}
                    onClick={() => selectRadioCard(data.incentiveId)}
                  >
                    <div className="text-center">
                      <div className="card-selections-icon">
                        <span
                          className={getIconClassName(data.lineOfBusiness)}
                        ></span>
                      </div>
                      <div className="card-overflow card-selections-header centered">
                        <h5>{data.incentiveName}</h5>
                      </div>
                      <div className="card-selections-content centered">
                        <p className="money-green card-overflow">
                          {"$" + data.totalEarnings.toLocaleString().toString() + " earned"}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        )}
    </>
  );
};

export default ActivePromotions;
