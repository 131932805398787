import "../../styles/gdk";

const ClassificationInput = () => {
  return (
    <div>
      If you are the sole owner of your LLC, your business is
      classified as a Single-Member LLC, also known as a Disregarded
      Entity for federal tax purposes.
      <br />
      <br />
      A disregarded entity should select the appropriate box for the
      tax classification of its owner.
      <br />
      <br />
      If your entity fits any of the below classifications, select
      Limited Liability Company (LLC):
      <br />
      <br />
      • LLC classified as a partnership for U.S. federal tax purposes
      <br />
      <br />• LLC that has filed Form 8832 or 2553 electing to be
      taxed as a corporation
  </div>
)};

export default ClassificationInput;
