import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/userContext";
import { useContext, useEffect } from "react";
import QuantumMetrics from "./QuantumMetrics";

const Error = () => {
  const navigate = useNavigate();
  var user = useContext(UserContext);
  const handleClick = () => {
    navigate("/");
  };
  useEffect(() => {
    if (user) {
      if (
        user.enrollmentStatus.taxInfo &&
        user.enrollmentStatus.bankingOnFile &&
        user.enrollmentStatus.participationIndicator
      ) {
        document.getElementById("error-home-button").hidden = false;
        document.getElementById("error-onboarding-button").hidden = true;
      } else {
        document.getElementById("error-home-button").hidden = true;
        document.getElementById("error-onboarding-button").hidden = false;
      }
    }
  }, []);
  return (
    <>
      <div className="container">
        <form>
          <div className="row">
            <div className="hidden-sm col-md-4">
              <img src="/images/geckoPose.png" alt="Gecko" />
            </div>
            <div className="col-sm-12 col-md-8">
              <div className="encounter-error-message">
                <div className="section-header">
                  <div className="section-header-title-subtext">
                    <h3 className="font--bold">
                      Oops! Seems we've encountered an error.
                    </h3>
                    <br />
                    <p>
                      <span>We apologize for the inconvenience.</span>
                    </p>
                    <br />
                    <p>
                      <button
                        id="error-home-button"
                        onClick={handleClick}
                        className="btn btn-primary"
                      >
                        Go Back Home
                      </button>
                      <button
                        id="error-onboarding-button"
                        onClick={handleClick}
                        className="btn btn-primary"
                      >
                        Go Back To Onboarding
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <QuantumMetrics />
    </>
  );
};
export default Error;
