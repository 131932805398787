import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div>
            <footer id="primary-footer">
                <div className="footer-logo icon-geico"></div>
                <div className="footer-links">
                    <div>
                        <p> 
                            <Link to="/privacyPolicy" target="_blank" rel="noopener noreferrer">Privacy Policy</Link>
                            {" | "}
                            <Link to="https://media.geico.com/legal/security_policy.htm" target="_blank" rel="noopener noreferrer">Security</Link>
                            {" | "}
                            <Link to="/termsOfUse" target="_blank" rel="noopener noreferrer">Terms of Use</Link>
                        </p>
                    </div>
                </div>
                <div id="footer-copyright">GEICO © 1996-<span id="footerDate">{(new Date().getFullYear())}</span></div>
            </footer>
        </div>
    );
};

export default Footer;
