import { Component } from "react";
import PrimaryNavigation from "./PrimaryNavigation";

export class Header extends Component {
  static displayName = Header.name;

  render() {
    return (
      <>
        <header id="primary-header" role="banner">
          <div className="header-logo" data-testid="header-logo">
            <a className="icon-geico" aria-label="GEICO Home" href="/">
              {" "}
            </a>
          </div>

          <div className="header-links">
            <ul>
              <li className="menu" data-testid="menu">
                <a
                  data-side-panel-trigger="menu"
                  className="hamburger-menu"
                  href="/"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span className="header-link">Menu</span>
                </a>
              </li>
            </ul>
          </div>
        </header>
        <PrimaryNavigation />
      </>
    );
  }
}

export default Header;
