import { Link } from "react-router-dom";
import QuantumMetrics from "./QuantumMetrics";

const PrivacyPolicy = () => {
  return (
    <>
    <div className="container">
      <div className="header">
        <h3> GEICO Rewards for Independent Producers Privacy Notice</h3>
      </div>
      <div className="content">
        <p>
          <em>Effective Date: April 12, 2024</em>
        </p>
        <p>
          <strong>Purpose and Scope</strong>
          <br />
          This GEICO Rewards for Independent Producers Privacy Notice (“Notice”)
          describes how we collect, use, store, disclose, and protect the
          information, that may be used to reasonably identify, directly or
          indirectly, you or a household (“Personal Information”), we collect
          about you in the context of the GEICO Rewards program for our
          independent producers. It also includes how to contact us if you have
          any questions about this Notice.
        </p>
        <p>
          This Notice applies to those independent producers and their
          employees, contractors or agents participating the GEICO Reward
          program independent producers of Government Employees Insurance
          Company and its affiliates (“GEICO”).
        </p>
        <p>
          However, this Notice does not create or form part of any employment
          contract or contract to provide services. If you provide services to
          GEICO through or in connection with another company, we are not
          responsible for that company’s privacy practices. This Notice does not
          apply to our handling of data that may be gathered about you as a
          consumer or customer of GEICO’s services. When you interact with us as
          a consumer or customer, the GEICO Privacy Policy applies. If you have
          any questions regarding how this Notice applies to you, or if you have
          an impairment requiring the provision of this Notice in a different
          format, please contact a member of GEICO’s agency management.
        </p>
        <p>
          <strong>
            What Kinds of Personal Information Do We Collect and Process?
          </strong>
          <br />
          In connection with your participation the GEICO Rewards program for
          our independent producers, we collect Personal Information either
          directly from you or, where applicable, from another person or entity,
          such as your employer, background check provider, or other source. The
          types of Personal Information we collect may include:
        </p>
        <p>
          <ul>
            <li>
              Personal details and contact information: This includes
              information such as name, home address, phone number, email
              address, government-issued identifiers, tax numbers, age, birth
              date, sex, marital status, photograph (e.g., badge photo), and
              emergency contact details.
            </li>
            <li>
              Financial account information: This includes information such as
              financial account and payment card details to make payments to or
              process payments.
            </li>
            <li>
              Performance and work history: This includes information relating
              to your job, such as your job title(s), supervisor, work phone,
              business email, correspondence from and with you.
            </li>
            <li>
              Audio and visual information related to your use of work
              resources: This includes information such as information captured
              on IT systems; security systems; and in conjunction with the use
              of GEICO equipment, accounts, and systems, such as information
              about your use of the GEICO company network and communication
              systems (including user IDs, passwords, IP addresses, device IDs,
              web logs, and audit trails of system access). This could also
              include geolocation information if you use a GEICO-issued device
              or access a GEICO network or system.
            </li>
            <li>
              Termination of relationship: This includes information, if
              applicable, such as date of and reason for termination of your
              employment with your agency, or termination of your agency’s
              appointment to sell policies for GEICO.
            </li>
          </ul>
        </p>
        <p>
          <strong>How Do We Use This Personal Information?</strong>
          <br />
          We collect, use, disclose, and store the Personal Information we
          collect for GEICO’s business purposes, which include, where
          applicable:
        </p>
        <p>
          <ul>
            <li>
              Management of the GEICO Rewards program: Solicitations for,
              enrollment in, and management of all aspects of your participation
              in the GEICO Rewards program including, but not limited to,
              administering the program, rewards, and associated tax
              obligations.
            </li>
            <li>
              Compliance: Compliance with legal and other requirements, such as
              tax and insurance, health and safety. We will also use your
              information when it is necessary to preserve, protect, or defend
              our rights in court; to respond to law enforcement or government
              agency requests, inspections, or discovery procedures; or where
              required or permitted by applicable laws, court orders, government
              regulations, or government authorities, which may include lawful
              access by courts or governmental authorities outside of your
              country of residence.
            </li>
            <li>
              Performance of business management operations: Maintaining
              accounts and internal directories, crisis management,
              due-diligence activities, business succession planning, and
              conducting internal analyses and audits. We may also use
              photographs and video recordings provided for or taken at
              GEICO-sponsored events or presentations in our promotional
              materials.
            </li>
            <li>
              Improve efficiencies and processes: Pursuing our legitimate
              interests of improving efficiencies and performance, internal
              management, effective administration, and ensuring that our
              services are carried out to an appropriate standard. For example,
              we may analyze workflows and capacity for the purposes of
              informing our recruitment strategies.
            </li>
            <li>
              Security: For purposes of safety and security as are necessary to
              protect GEICO and our, GEICO data, information, and intellectual
              property, against security risks of all types.
            </li>
            <li>
              Monitoring: To monitor GEICO devices and computers, and
              communications to and from our systems, in order to manage our
              resources, plan, and protect our rights, including intellectual
              property rights. To prevent and detect inappropriate or malicious
              activities we actively monitor activities and communications made
              on GEICO’s systems, company-provided devices as well as personal
              devices.
              <br />
              PLEASE BE ADVISED that any and all telephone conversations, email
              or transmissions, or internet access or usage to or from GEICO,
              including but not limited to a computer, telephone, wire, radio or
              electromagnetic, photoelectronic or photo-optical system, is
              subject to monitoring at any and all times and by any lawful means
              when involving a GEICO device, system, or application. This
              monitoring may extend to personal business conducted on private
              (i.e., non-GEICO) accounts while accessing or connecting to GEICO
              equipment or systems.
            </li>
            <li>
              Please note if you participate in a recorded presentation or
              internal call, regardless of whether you are participating at a
              GEICO facility or from your home or another location, background
              noise and information may be inadvertently picked up in the course
              of such recordings. The purpose of recording is to facilitate the
              rendering of quality business services, and your consent is
              assumed by virtue of your employment with GEICO. GEICO disclaims
              any affirmative duty to respond to monitored or recorded
              background noise.
            </li>
          </ul>
        </p>
        <p>
          <strong>How and Why Do We Disclose Personal Information?</strong>
          <br />
          We will disclose your Personal Information to the following types of
          entities or in the following circumstances, as applicable:
        </p>
        <p>
          <ul>
            <li>
              Internally within GEICO to carry out the purposes described in
              this Notice, various personnel in business units such as payroll,
              IT, legal, and finance.
            </li>
            <li>
              External service providers, such as: payment processors and
              vendors; IT system providers; background check providers; travel
              providers; auditors; providers of gifts and gratuities; and legal,
              financial, medical and other professional advisors, all of whom
              are required to protect your information as part of providing
              services to us.
            </li>
            <li>
              Applicable tax, social security, insurance departments and other
              applicable authorities.
            </li>
            <li>
              Where we are required to do so pursuant to legal process; or to
              comply with a relevant legal and regulatory obligation.
            </li>
            <li>
              Where the health or safety of an individual may be endangered, and
              sharing the Personal Information is reasonably necessary to
              mitigate the danger.
            </li>
            <li>
              As is typical of a business, we may buy or sell assets. In such
              transactions, including during the diligence and evaluation period
              of such transactions, such information can be one of the
              transferred business assets. Accordingly, your information may
              also be disclosed, where permitted by applicable law, in
              connection with a corporate restructuring, sale, or assignment of
              assets, merger, divestiture, or other changes of our control or
              financial status.
            </li>
          </ul>
        </p>
        <p>
          <strong>Retention of Personal Information</strong>
          <br />
          GEICO will retain your Personal Information for as long as is
          necessary for the purposes set out in this Notice and to operate the
          business and comply with our legal obligations, resolve disputes, and
          enforce our agreements and written policies. As such, retention of
          your Personal Information may extend beyond the dates you participate
          in the Rewards program for independent producers. Please note that if
          GEICO has de-identified information so that it can no longer be tied
          to you individually, GEICO maintains the right to use and share such
          de-identified information for any purposes and for an indefinite
          period of time.
        </p>
        <p>
          <strong>How We Protect Your Personal Information</strong>
          <br />
          GEICO employs a variety of technical, physical, and administrative
          measures designed to protect the integrity, confidentiality, security,
          and availability of your Personal Information, and to comply with
          applicable legal requirements for data privacy and information
          security. GEICO limits access to internal systems that hold Personal
          Information to individuals who need access for a legitimate business
          purpose. However, no method of transmission of information, and no
          means of electronic or physical storage, can be absolutely secure.
        </p>
        <p>
          <strong>California Residents</strong>
          <br />
          Under the California Consumer Privacy Act (CCPA), as amended by the
          California Privacy Rights Act, California residents who are former or
          current GEICO job applicants, employees or independent contractors
          have the following rights: (1) the right to know, which is the right
          to request that GEICO disclose information about, or a copy of, the
          types of Personal Information we collect, use, and disclose about
          them; (2) the right to delete, which is the right to request that we
          delete Personal Information that is collected or maintained by GEICO;
          (3) the right to request correction of inaccurate Personal
          Information, which is the right to ask GEICO to correct inaccurate
          Personal Information that it maintains about them; and (4) the right
          to non-discrimination, which is the right to be free from
          discrimination for exercising any consumer rights under the CCPA. We
          may limit our responses to any requests under the CCPA as permitted by
          law. For example, we may need to maintain certain Personal Information
          to provide employment benefits to you or for legal and compliance
          purposes.
        </p>
        <p>
          To exercise your rights under the CCPA, please click here and fill out
          the requested information or, in the alternative, call 301-986-4656.
          For verification purposes only, we may collect a limited number of
          personal identifiers, send those through a secure verification system,
          and ask follow-up questions through our online portal or
          representative, before confirming whether their identity has been
          verified. You may also designate an authorized agent to make a request
          to know or delete on your behalf. To designate an authorized agent,
          you must provide the natural person, or entity registered with the
          California Secretary of State, with written permission to submit such
          a request. You (or your agent) will still be required to verify your
          identity. However, if an authorized agent has been provided with power
          of attorney in accordance with the California Probate Code, then no
          additional written authorization is required. All agents must submit
          proof to GEICO that they have been authorized to act on their behalf.
          Please see the preceding portion of this Notice, wherein we explain
          what categories of Personal Information we collect; where we collect
          the Personal Information from; the purposes for which we collect
          Personal Information; and who we disclose the Personal Information to,
          if at all.
        </p>
        <p>
          Sensitive Personal Information: After obtaining (or having a
          third-party or service provider obtain) consent from an individual to
          do so, GEICO may collect information that is considered Sensitive
          Personal Information under Data Protection Laws. Sensitive Personal
          Information includes but is not limited to credit card information,
          driver's license numbers, social security numbers, or information
          concerning an individual’s health. Credit card information is
          collected for financial transactions; driver's license numbers and
          social security numbers are obtained to verify individuals and offer
          benefits related to the GEICO Rewards program for independent
          producers and complying with applicable laws. GEICO only uses or
          discloses Sensitive Personal Information for purposes specified under
          the CCPA, and such use or disclosure is only done to the extent it is
          reasonably necessary and proportionate to those purposes.
        </p>
        <p>
          GEICO has not sold (as this term is defined under CCPA) personnel
          Personal Information in the 12-month period preceding the Effective
          Date of this Privacy Notice. We have shared (as that term is defined
          under CCPA) IP addresses for cross-context behavioral advertising
          purposes in the past 12-month period; however, we do recognize
          standard opt-out preference signals in a frictionless manner, as
          explained in the "What Kinds of Personal information Do We Collect and
          Process" section, above. GEICO retains each category of Personal
          Information, including Sensitive Personal Information, for legal,
          compliance and business purposes. The retention criteria vary
          depending on these purposes which impact, among other processes,
          providing applicable employee benefits and meeting legal requirements.
          However, GEICO's policy is to retain Personal Information for as long
          as reasonably necessary for any of the purposes disclosed above. GEICO
          does not knowingly disclose the Personal Information of anyone under
          16 years of age.
        </p>
        <p>
          <strong>How to Contact Us About This Notice</strong>
          <br />
          Please feel free to contact the GEICO Privacy Team with questions or
          concerns regarding this Notice and our privacy practices by emailing
          us at&nbsp;
          <Link to="mailto: privacypolicy@geicomail.com">
            privacypolicy@geicomail.com
          </Link>
          .
        </p>
        <p>
          <strong>Notice Updates</strong>
          <br />
          We may modify this Notice from time to time. When we make changes, we
          will revise the effective date of this Notice.
        </p>
      </div>
    </div>
    <QuantumMetrics />
    </> 
  );
};

export default PrivacyPolicy;
