import { useEffect, useRef } from "react";
import { GdkTooltip } from "@gdk/tooltip";

const ToolTip = ({idName, triggerId, body}) => {
    var tooltipRef = useRef();
    useEffect(() => {
        tooltipRef.current = new GdkTooltip({
            content: `#${triggerId}`
        });
    }, []);

    return (
        <div className="tooltip-container">
            <a href="/#" id={triggerId} className="tooltip-trigger" data-tooltip-view={idName} aria-labelledby="input-label-id-5" tabIndex="-1">
                <span className="icon-question-mark"></span>
            </a>
            <div id={idName} className="tooltip">
                <div>{body}</div>
                <button className="icon-close" aria-label="Close tooltip"></button>
            </div>
        </div>
    )
}
export default ToolTip;