import "../styles/Earnings.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/gdk";
import { convertDate } from "../helpers/historyHelper";
import QuantumMetrics from "./QuantumMetrics";
import * as rewardsApi from "../services/ApiServices/ApiService";
import { logError } from "../services/ApiServices/LoggingService";

const addRow = (earning) => {
  let body = document.getElementById("earnings-body");
      const row = document.createElement("tr");

      const name = document.createElement("td");
      name.scope = "col";
      name.textContent = earning.promotionName;
      row.appendChild(name);

      const type = document.createElement("td");
      type.scope = "col";
      type.textContent = earning.promotionType;
      row.appendChild(type); 

      const status = document.createElement("td");
      status.scope = "col";
      status.textContent = earning.paymentStatus;
      row.appendChild(status);

      const earn = document.createElement("td");
      earn.scope = "col";
      earn.textContent = "$"+earning.paymentAmount.toLocaleString().toString();
      row.appendChild(earn);

      const date = document.createElement("td");
      date.scope = "col";
      date.textContent = convertDate(earning.paymentDate);
      row.appendChild(date);


      body.appendChild(row);
}
const Earnings = () => { 
  const [history, setHistory] = useState(null);
  const [earningsList, setEarningsList] = useState(null);
  const [tokenExists, setTokenExists] = useState(false);
  const navigate = useNavigate();

  async  function getUserPaymentHistory(token) {
    const handleError = (error) => {
      logError(error);
    };
    const handleSuccess = (responseData) => {
      setHistory(responseData);
    }
    await rewardsApi.getUserPaymentHistory(token, handleSuccess, handleError);
  }

  useEffect(() => {
    if (history) {
      if (!earningsList) {
        setEarningsList(history.list);
      }

      else {
        let temp = earningsList;
        temp.push.apply(temp, history.list);
        setEarningsList(temp);
      }

      if (!tokenExists && history.token) {
        setTokenExists(true);
      }

      let sorted;
      if (earningsList) {
        sorted = earningsList;
        
      }

      else {
        sorted = history.list;
      }

      let body = document.getElementById("earnings-body");
      if (body.hasChildNodes()) {
        while (body.firstChild) {
          body.firstChild.remove();
        }
      }
      sorted.forEach((earning) => {
        addRow(earning);
      });
    }
  },[history])

  useEffect(() => {
    getUserPaymentHistory("");
    setEarningsList(null);
  }, [])
  
  return (
    <>
    <div className="Earnings-container">
      <div className="container-fluid">
        <div className="header-container">
          <h3 className="header-main">Earnings History Dashboard</h3>
        </div>
      </div>
      <div className="data-table">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col">Promotion Name</th>
              <th scope="col">Promotion Type</th>
              <th scope="col">Payment Status</th>
              <th scope="col">Earnings</th>
              <th scope="col">Date</th>
            </tr>
          </thead>
          <tbody id = "earnings-body"></tbody>
        </table>
        {history && history.token && (
        <a className="Earnings-footer" data-testid="submit" 
          onClick={() => {getUserPaymentHistory(history.token)}
        }>
          Show More
        </a>) }
      </div>
      <button type="button" data-testid="submit" 
        className="btn btn--secondary btn--pull-right" onClick={() => {navigate("/home");}
      }>
        Return Home
      </button>
    </div>
    <QuantumMetrics />
    </> 
  );
};

export default Earnings;
