import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { GdkLoader } from "@gdk/loader";
import "../styles/gdk";
import QuantumMetrics from "./QuantumMetrics";
const Landing = () => {
  const gdkLoader = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    gdkLoader.current = new GdkLoader({
      content: "#full-loader",
      type: "section",
    });
    gdkLoader.current.show();
    //getUserInfo();

    gdkLoader.current.hide();
  }, [navigate]);

  return (
    <>
      <div className="container">
        <div id="full-loader" className="loader-container" data-testid="loader">
          <div className="loader-content">
            <div className="loader loader--animated" aria-label="Please wait, it is loading">
              <div className="animated-loader-copy">
                Loading your rewards dashboard
                <div className="ellipses">
                  <span className="ellipses-1">.</span>
                  <span className="ellipses-2">.</span>
                  <span className="ellipses-3">.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <QuantumMetrics />
    </>
  );
};

export default Landing;
