
import { useContext } from "react";
import { UserPendingPromotionsContext } from "../../context/userContext";
import "@gdk/card-selections/dist/styles/gdk-card-selections.css";
import "../../styles/ActivePromotions.css";

const PendingPromotions = (principalFilter) => {
  const userPendingPromotions = useContext(UserPendingPromotionsContext);  
  const getIconClassName = (lob) => {
    switch (lob.toUpperCase()) {
      case "PERSONALAUTO":
        return "geico-icon icon--primary-blue icon-vehicle";
      case "COMMERCIALAUTO":
        return "geico-icon icon--primary-blue icon-commercial-ins";
      default:
        return "geico-icon icon--primary-blue icon-vehicle";
    }
  };

  return (
    <div className="card-selection-wrapper" id="card-wrapper">
      {userPendingPromotions && userPendingPromotions.length > 0 && (
        <>
          <h4>Pending Promotions</h4>
          <div className="card-selections" id="card-selections-container">
            {userPendingPromotions.map(function (data) {
              return (
                <div
                  key={data.id}
                  className={"radio-card-disabled card-width"}
                  id={data.name}
                >
                  <div className="text-center">
                    <div className="card-selections-icon">
                      <span
                        className={getIconClassName(data.lineOfBusiness)}
                      ></span>
                    </div>
                    <div className="card-overflow card-selections-header centered">
                      <h5>{data.name}</h5>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default PendingPromotions;
