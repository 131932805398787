import "../../styles/gdk";

const FatcaCodeTooltipContent = () => {
  return (
    <div tabIndex="0">
      Exemption from Foreign Account Tax Compliance Act (FACTA)
      reporting code.
      <br />
      <br />
      The following codes identify payees that are exempt from
      reporting under FATCA.
      <br />
      These codes apply to persons submitting this form for accounts
      maintained outside of the United States by certain foreign
      financial institutions.
      <br />
      Therefore, if you are only submitting this form for an account
      you hold in the United States, you may leave this field blank.
      Consult with the person requesting this form if you are
      uncertain if the financial institution is subject to these
      requirements. A requester may indicate that a code is not
      required by providing you with a Form W-9 with “Not
      Applicable” (or any similar indication) entered on the line
      for a FATCA exemption code.
      <br />
      <br />
      A — An organization exempt from tax under section 501(a) or
      any individual retirement plan as defined in section
      7701(a)(37).
      <br />
      <br />
      B — The United States or any of its agencies or
      instrumentalities.
      <br />
      <br />
      C — A state, the District of Columbia, a U.S. commonwealth or
      territory, or any of their political subdivisions or
      instrumentalities.
      <br />
      <br />
      D — A corporation the stock of which is regularly traded on
      one or more established securities markets, as described in
      Regulations section 1.1472-1(c)(1)(i).
      <br />
      <br />
      E — A corporation that is a member of the same expanded
      affiliated group as a corporation described in Regulations
      section 1.1472-1(c)(1)(i)
      <br />
      <br />
      F — A dealer in securities, commodities, or derivative
      financial instruments (including notional principal contracts,
      futures, forwards, and options) that is registered as such
      under the laws of the United States or any state.
      <br />
      <br />
      G — A real estate investment trust.
      <br />
      <br />
      H — A regulated investment company as defined in section 851
      or an entity registered at all times during the tax year under
      the Investment Company Act of 1940.
      <br />
      <br />
      I — A common trust fund as defined in section 584(a).
      <br />
      <br />
      J — A bank as defined in section 581.
      <br />
      <br />
      K — A broker.
      <br />
      <br />
      L — A trust exempt from tax under section 664 or described in
      section 4947(a)(1).
      <br />
      <br />
      M — A tax-exempt trust under a section 403(b) plan or section
      457(g) plan.
      <br />
      <br />
      Note: You may wish to consult with the financial institution
      requesting this form to determine whether the FATCA code
      and/or exempt payee code should be completed.
    </div>            
  );
};
export default FatcaCodeTooltipContent;
