import { ssnValidate } from "../components/TaxFormInputs/SsnInput";
import { feinValidate } from "../components/TaxFormInputs/FeinInput";
import * as rewardsApi from "../services/ApiServices/ApiService";
import { logError } from "../services/ApiServices/LoggingService";

export const addError = (parent, text) => {
  if (document.getElementById(parent.id + "-error") === null) {
    const msg = document.createElement("span");
    msg.id = parent.id + "-error";
    msg.innerHTML = text;
    msg.className = "form-message";
    parent.appendChild(msg);
    parent.setAttribute("aria-invalid", true);
    parent.className = parent.className.replace(
      "form-field",
      "form-field--error"
    );
  }
};

export const removeError = (parent) => {
  if (document.getElementById(parent.id + "-error") !== null) {
    document.getElementById(parent.id + "-error").remove();
    parent.setAttribute("aria-invalid", false);
    parent.className = parent.className.replace(
      "form-field--error",
      "form-field"
    );
  }
};

export const addStateError = (parent, text) => {
  if (document.getElementById(parent.id + "-error") === null) {
    const msg = document.createElement("span");
    msg.id = parent.id + "-error";
    msg.innerHTML = text;
    msg.className = "form-message";
    parent.appendChild(msg);
    document.getElementById("states").setAttribute("aria-invalid", true);
    parent.className = parent.className.replace(
      "form-field",
      "form-field--error"
    );
  }
};

export const removeStateError = (parent) => {
  if (document.getElementById(parent.id + "-error") !== null) {
    document.getElementById(parent.id + "-error").remove();
    document.getElementById("states").setAttribute("aria-invalid", false);
    parent.className = parent.className.replace(
      "form-field--error",
      "form-field"
    );
  }
};

export const getUserInfoFromLoginHeader = async () => {
  let apiCallSuccess = false;
  let result;

  const handleError = (error) => {
    logError(error);
  };
  const handleSuccess = (responseData) => {
    apiCallSuccess = true;
    result = responseData;
  };

  await rewardsApi.getUserInfo(handleSuccess, handleError);
  if (apiCallSuccess) {
    return result;
  }
};

export const taxFormValidate = () => {
  let allValid = true;

  if (document.getElementById("ssn-input-field").disabled === true) {
    if (!feinValidate()) {
      allValid = false;
    }
  } else if (document.getElementById("fein-field").disabled === true) {
    if (!ssnValidate()) {
      allValid = false;
    }
  } else {
    addError(
      document.getElementById("ssn-form"),
      "Please enter a valid Social Security Number"
    );
    addError(
      document.getElementById("fein-form"),
      "Please enter a valid Employer Identification Number"
    );
    document.getElementById("fein-field").disabled = false;
    document.getElementById("fein-value").disabled = false;
    document.getElementById("ssn-input-field").disabled = false;
    document.getElementById("ssn-input-value").disabled = false;
    allValid = false;
  }

  if (allValid) {
    return true;
  } else {
    return false;
  }
};

export const RoutingAccountValidate = async () => {
  let RoutingNumber = document.getElementById("RoutingNumber");
  let RoutingNumberForm = document.getElementById("RoutingNumber-form");
  let AccountNumber = document.getElementById("AccountNumber");
  let AccountNumberForm = document.getElementById("AccountNumber-form");
  let VerifyAccountNumber = document.getElementById("VerifyAccountNumber");
  let VerifyAccountNumberForm = document.getElementById(
    "VerifyAccountNumber-form"
  );

  let allValid = true;
  if (RoutingNumber.value.length !== 9) {
    addError(RoutingNumberForm, "Please enter a valid 9 digit routing number");
    allValid = false;
  } else {
    removeError(RoutingNumberForm);
  }

  if (AccountNumber.value.length === 0) {
    addError(AccountNumberForm, "Please enter a valid account number");
    allValid = false;
  } else {
    removeError(AccountNumberForm);
  }

  if (
    VerifyAccountNumber.value.length === 0 ||
    VerifyAccountNumber.value !== AccountNumber.value
  ) {
    addError(
      VerifyAccountNumberForm,
      "Please ensure the verified account number matches the account number"
    );
    allValid = false;
  } else {
    removeError(VerifyAccountNumberForm);
  }

  if (allValid) {
    return true;
  }
};
