import { GdkTooltip } from "@gdk/tooltip";
import { useEffect, useRef } from "react";
import { GdkFein } from "@gdk/fein";
import "../../styles/gdk";
import "@gdk/base/dist/js/jquery.maskedinput.geico";
import { addError, removeError } from "../../helpers/validationHelper";

export const feinValidate = () => {
  let feinValue = document.getElementById("fein-value").value;
  if (feinValue.length !== 10 || feinValue.includes("_")) {
    addError(
      document.getElementById("fein-form"),
      "Please enter a valid Employer Identification Number"
    );
    return false;
  }
  removeError(document.getElementById("fein-form"));
  removeError(document.getElementById("ssn-form"));
  return true;
};

const FeinInput = ({ lock = false }) => {
  var fein = useRef();
  var tooltipfein = useRef();
  useEffect(() => {
    fein.current = new GdkFein({
      content: "#fein",
    });
    tooltipfein.current = new GdkTooltip({
      content: "#tooltip-trigger-fein",
    });
    document.getElementById("fein-field").onchange = () => {
      removeError(document.getElementById("fein-form"));
      removeError(document.getElementById("ssn-form"));
    };
    if (lock) {
      document.getElementById("fein-field").disabled = true;
      document.getElementById("fein-value").disabled = true;
    } else {
      document.getElementById("fein-field").disabled = false;
      document.getElementById("fein-value").disabled = false;
    }
  }, [lock]);

  return (
    <div>
      <div id="fein">
        <div
          id="fein-form"
          className="formDisplay federal-employment-identification-number-wrapper form-field"
        >
          <div className="tooltip-label-container">
            <label htmlFor="fein-field">Employer Identification Number</label>
            <div className="tooltip-container ">
              <a
                href="/#"
                id="tooltip-trigger-fein"
                className="tooltip-trigger"
                data-tooltip-view="tooltip-fein"
                aria-labelledby="input-label-id-5"
                tabIndex="-1"
              >
                <span className="icon-question-mark"></span>
              </a>
              <div id="tooltip-fein" className="tooltip">
                <div tabIndex="0">
                  At GEICO, we understand the importance of your privacy and
                  security. The information you provide will be kept
                  confidential and secure, and your Employer Identification
                  Number will be encrypted. It will not be sold or
                  redistributed. To learn more about how we protect your
                  personal information, please review our privacy &amp; security
                  policy:&nbsp;
                  <a
                    href="https://media.geico.com/legal/privacy_policy.htm"
                    className=""
                  >
                    Privacy Policy
                  </a>
                </div>
                <button
                  className="icon-close"
                  aria-label="Close tooltip"
                ></button>
              </div>
            </div>
          </div>
          <input
            data-testid="feinTest"
            aria-invalid="true"
            id="fein-field"
            name="fein-field"
            type="tel"
            className="federal-employment-identification-number"
            autoComplete="off"
            spellCheck="false"
            placeholder="__-_______"
          />
          <input
            data-testid="feinInputTest"
            aria-invalid="true"
            type="password"
            id="fein-value"
            name="fein-value"
            className="secureInput"
            maxLength="10"
            autoComplete="off"
            spellCheck="false"
            aria-hidden="true"
          />
          <a className="fein-show-hide" href="/#" tabIndex="-1">
            <span className="icon-hide" aria-label="Hide"></span>
          </a>
        </div>
      </div>
    </div>
  );
};
export default FeinInput;
