import { Route, Routes } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import Layout from "./components/Layout";
import "./custom.css";
import "./styles/gdk";
import UserContextProvider from "./context/UserContextProvider";
import UserSummaryContextProvider from "./context/UserSummaryContextProvider";
import UserIncentiveSummaryContext from "./context/UserIncentiveSummaryContextProvider";
import UserPendingPromotionsContext from "./context/UserPendingPromotionsContextProvider";
import FeatureFlagContextProvider from "./context/FeatureFlagContextProvider";

export default function App() {
  return (
    <>
      <UserContextProvider>
        <UserSummaryContextProvider>
          <UserIncentiveSummaryContext>
            <UserPendingPromotionsContext>
              <FeatureFlagContextProvider>
                <Layout>
                  <Routes>
                    {AppRoutes.map((route, index) => {
                      const { element, ...rest } = route;
                      return <Route key={index} {...rest} element={element} />;
                    })}
                  </Routes>
                </Layout>
              </FeatureFlagContextProvider>
            </UserPendingPromotionsContext>
          </UserIncentiveSummaryContext>
        </UserSummaryContextProvider>
      </UserContextProvider>
    </>
  );
}
