import { Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap";
import { useState } from "react";
import '../styles/ConfirmationModal.css'
const returnHome = () => {
    window.location.href = "/home";
}
const ConfirmationModal = ({setShow}) => {

  const [modal, setModal] = useState(true);
  const toggle = () =>  {
    setModal(!modal);
    setShow(false);
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} fade={false} 
        onClosed={() => setShow(false)} contentClassName="Confirmation-modal-style" >
        <ModalHeader className="Confirmation-modal-header">
          <button className="Confirmation-modal-close" data-testid="confirmationClose" onClick={toggle}>
            x
          </button>
          <div className="Confirmation-modal-title" data-testid="confirmationTest">
            Are you sure you want to cancel?
          </div>
        </ModalHeader>
        <ModalBody className="Confirmation-modal-body">
          <div>
            <h5>
                Your information will remain unchanged
            </h5>
          </div>
        </ModalBody>
        <ModalFooter className="Confirmation-modal-footer">
          <button className="btn btn--secondary Confirmation-modal-secondary-button" 
            data-testid="confirmationNo" onClick={toggle} >
            No
          </button>
          <button className="btn btn--primary Confirmation-modal-primary-button" 
            onClick={ () => {
                toggle();
                returnHome();
            }}
            data-testid="confirmationYes" >
            Yes  
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default ConfirmationModal;