import React, { useState } from "react";
import { Modal } from "reactstrap";
import "../styles/gameModal.css";
import { useNavigate } from "react-router-dom";

const navigateToEarnings = () => {
  window.location.href = "/earnings";
}; 

const GameModal = (props) => {
  const [modal, setModal] = useState(true);
  const navigate = useNavigate();

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        fade={false}
        contentClassName="custom-modal-style"
      >
        {props.numberOfSpins >= 0 && (
          <button className="modal-close" onClick={toggle}>
            x
          </button>
        )}
        {props.numberOfSpins === 0 && (
          <button className="modal-close" onClick={() => {navigate("/home");}}>
            x
          </button>
        )}
        <br />
        <div className="modal-title">WINNER!</div>
        <div className="modal-body">
          <h2>
            Congratulations, you won a prize of{" "}
            <span className="money-green">{props.prizeAmount}</span>!
          </h2>
          {props.numberOfSpins >= 2 && (
            <h3>
              You have{" "}
              <span className="money-green">{props.numberOfSpins}</span> spins
              remaining.
            </h3>
          )}
          {props.numberOfSpins === 1 && (
            <h3>
              You only have <span className="money-green">1</span> spin left for
              now. Good luck!
            </h3>
          )}
          {props.numberOfSpins === 0 && (
            <h4>
              No spins left. You can return to the Home page or view your total
              earnings.
            </h4>
          )}
        </div>
        <div className="modal-buttons">
          <button id="game-modal-return-home" className="btn btn--secondary " onClick={() => {navigate("/home");}}>
            Return Home
          </button>
          {props.numberOfSpins <= 0 && (
            <button className="btn btn--primary " onClick={navigateToEarnings}>
              View Earnings
            </button>
          )}
          {props.numberOfSpins > 0 && (
            <button className="btn btn--primary " onClick={toggle}>
              Spin Again
            </button>
          )}
        </div>
        <div>
          *No additional action required. Payments are auto-deposited to the
          account on file.
        </div>
      </Modal>
    </div>
  );
};

export default GameModal;
