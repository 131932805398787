import Home from "./components/Home";
import Game from "./components/Game";
import BankingDetails from "./components/BankingDetails";
import TaxForm from "./components/TaxForm";
import Onboarding from "./components/Onboarding";
import Landing from "./components/Landing";
import Earnings from "./components/Earnings";
import Error from "./components/Error";
import AccessDenied from "./components/AccessDenied";
import SessionExpired from "./components/SessionExpired";
import LocalEcamsLogin from "./components/Local/LocalEcamsLogin";
import LocalLogout from "./components/Local/LocalLogout";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfUse from "./components/TermsOfUse";
import RewardsOffline from "./components/RewardsOffline";

const AppRoutes = [
  {
    index: true,
    element: <Landing />
  },
  {
    path: "/home",
    element: <Home />
  },
  {
    path: "/game",
    element: <Game />
  },
  {
    path: "/bankingDetails",
    element: <BankingDetails />
  },
  {
    path: "/taxForm",
    element: <TaxForm />
  },
  {
    path: "/onboarding",
    element: <Onboarding />
  },
  {
    path: "/earnings",
    element: <Earnings />
  },
  {
    path: "/error",
    element: <Error />
  },
  {
    path: "/accessDenied",
    element: <AccessDenied />
  },
  {
    path: "/sessionExpired",
    element: <SessionExpired />
  },
  {
    path:"/ecamsLogin",
    element: <LocalEcamsLogin />
  },
  {
    path:"/logout",
    element: <LocalLogout />
  },
  {
    path: "/privacyPolicy",
    element: <PrivacyPolicy />
  },
  {
    path: "/termsOfUse",
    element: <TermsOfUse />
  },
  {
    path: "/rewardsOffline",
    element: <RewardsOffline />
  }
];

export default AppRoutes;
