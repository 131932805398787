import { useEffect, useState, useRef } from "react";
import * as rewardsApi from "../services/ApiServices/ApiService";
import { logError } from "../services/ApiServices/LoggingService";
import { GdkToasts } from "@gdk/toasts";
import { useLocation } from "react-router-dom";
import QuantumMetrics from "./QuantumMetrics";

const SessionExpired = () => {
  const gdkToast = useRef(null);
  const location = useLocation();
  const [toastHeader, setToastHeader] = useState("");
  const [toastDescription, setToastDescription] = useState("");

  useEffect(() => {
    async function redirectToEcams() {
      const handleError = (error) => {
        logError(error);
      };

      const handleSuccess = (responseData) => {
        setTimeout(() => {
          window.location.href = responseData.logoutUrl;
        }, 3000);
      };

      await rewardsApi.getExternalUrls(handleSuccess, handleError);
    }

    gdkToast.current = new GdkToasts({
      content: "#confirmation-toast",
      type: "update",
      autoShow: false,
      floatTime: 3000,
      closeBtn: true,
      baseTimer: true,
    });

    if (location.state != null) {
      if (location.state.redirectUrl != null) {
        setToastHeader("Your Session has ended.");
        setToastDescription(
          "For the protection of your agency and customers, your GEICO session has ended. Please log back in to rewards application"
        );
        gdkToast.current.show();
        redirectToEcams();
      }
    }
  });

  return (
    <>
      <div className="container">
        <div id="confirmation-toast" className="toasts-container">
          <div className="toasts toasts--confirmation">
            <div>
              <span className="icon-confirmation"></span>
            </div>
            <div role="status">
              <div className="toasts--heading">{toastHeader}</div>
              <div className="toasts--content">{toastDescription}</div>
            </div>
            <button className="toasts--close-btn icon-close">
              <div id="toasts--base-timer"></div>
            </button>
          </div>
        </div>
      </div>
      <QuantumMetrics />
    </>
  );
};

export default SessionExpired;
