import { useState } from "react";
import * as rewardsApi from "../../services/ApiServices/ApiService";
import { logError } from "../../services/ApiServices/LoggingService";
import UpcomingPromotionModal from "./UpcomingPromotionModal";
import "../../styles/UpcomingPromotions.css";
import "@gdk/card-selections/dist/styles/gdk-card-selections.css";

const UpcomingPromotionCard = ({
  id,
  cardTitle,
  promotionDescription,
  daysRemaining,
  index
}) => {
  var incentiveIdList = [id];
  const [modalVisible, setModalVisible] = useState(false);

  const Enroll = async () => {
    const handleError = (error) => {
      logError(error);
    };
    const handleSuccess = (responseData) => {
      console.log(responseData.response);
      window.location.reload();
    };
    await rewardsApi.Enroll(
      { data: incentiveIdList },
      handleSuccess,
      handleError
    );
  };

  const handleSubmit = async () => {
    Enroll();
  };

  const getClassName = (index) => {
    switch (index) {
      case 0:
        return "upcoming-campaign-cards";
      default:
        return "upcoming-campaign-cards offset-lg-1";
    }
  };
  const toggle = () => setModalVisible(!modalVisible);

  return (
    <div>
      {modalVisible && (
        <UpcomingPromotionModal
          setShow={setModalVisible}
          cardTitle={cardTitle}
          promotionDescription={promotionDescription}
          daysRemaining={daysRemaining}
        />
      )}
      <div
        style={{}}
        className={getClassName(index) + " radio-cards-container"}
        id={id}
      >
        <h5 className="upcoming-campaign-cards-head card-overflow">
          {cardTitle}
        </h5>
        <p className="days-left-counter card-overflow">
          {daysRemaining.toString()} days left
        </p>
        <p className="card-overflow" style={{ marginBottom: 0 }}>
          {promotionDescription}
        </p>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className="card-overflow"
          href="#"
          onClick={(event) => {
            event.preventDefault();
            toggle();
          }}
        >
          Learn more
        </a>
        <div style={{ marginTop: "2rem" }} className="btn-center">
          <button
            type="button"
            className="btn btn--secondary btn--full-mobile"
            onClick={() => handleSubmit()}
          >
            <span>Activate</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpcomingPromotionCard;
