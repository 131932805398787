import { Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap";
import { useState, useEffect } from "react";
import "../../styles/ActivePromotionModal.css";
import { convertDate, getDaysRemaining } from "../../helpers/incentiveHelper";

const ActivePromotionModal = ({setShow,incentive}) => {

  const [modal, setModal] = useState(true);
  const [rewardType, setRewardType] = useState("");
  const [promotionType, setPromotionType] = useState("");

  const toggle = () => setModal(!modal);

  useEffect(() => {
    switch (incentive.incentiveType.toUpperCase()) {
      case "GAMIFICATION":
        setRewardType("spins");
        break;
      default:
        setRewardType("payouts");
        break;
    }

    switch (incentive.promotionType.toUpperCase()) {
      case "NEWBUSINESS":
        setPromotionType("policies");
        break;
      case "QUOTES":
        setPromotionType("quotes");
        break;
      default:
        setPromotionType("events");
        break;
    }
  }, []);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} fade={false} scrollable={true}
      onClosed={() => setShow(false)} contentClassName="ActivePromotion-modal-style" >
        <ModalHeader className="ActivePromotion-modal-header">
          <button className="ActivePromotion-modal-close" onClick={toggle}>
            x
          </button>
          <div className="ActivePromotion-modal-title">
            {incentive.incentiveName}
          </div>
          {getDaysRemaining(incentive.endDate) > 0 && (
            <div className="ActivePromotion-modal-subtitle">
              Promotion Dates: {convertDate(incentive.startDate)} - {convertDate(incentive.endDate)}
            </div>
          )}
          {getDaysRemaining(incentive.endDate) <= 0 && (
            <div className="ActivePromotion-modal-subtitle">
              Promotion Ended. Redeem {rewardType} by {convertDate(incentive.redemptionEndDate)}
            </div>
          )}
        </ModalHeader>
        <ModalBody>
          <div className="ActivePromotion-modal-body">
            <br/>
            <p>
              <b>Line of Buisness:</b> {incentive.lineOfBusiness}
            </p>
            {incentive.lineOfBusiness !== incentive.product && (
              <p>
                <b>Product: </b>{incentive.product}
              </p>
            )}
            <b>Description:</b>
            <p>{incentive.description}</p>
            <p className="ActivePromotion-modal-footer-text">
              Earn {rewardType} through {promotionType}
            </p>
            {(getDaysRemaining(incentive.endDate) > 0 && rewardType === "spins") && (
              <p className="ActivePromotion-modal-footer-text">
                Any leftover {rewardType} will expire {convertDate(incentive.redemptionEndDate)}
              </p>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn--primary ActivePromotion-modal-button" onClick={toggle} >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default ActivePromotionModal;