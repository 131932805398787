import { Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap";
import { useState } from "react";
import "../../styles/UpcomingPromotionModal.css";

const UpcomingPromotionModal = ({
  setShow,
  cardTitle,
  promotionDescription,
  daysRemaining
}) => {
  const [modal, setModal] = useState(true);
  const toggle = () => setModal(!modal);
  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        fade={false}
        scrollable={true}
        onClosed={() => setShow(false)}
        contentClassName="UpcomingPromotion-modal-style"
      >
        <ModalHeader style={{ display: "inline" }}>
          <button className="UpcomingPromotion-modal-close" onClick={toggle}>
            x
          </button>
          <div className="UpcomingPromotion-modal-title">{cardTitle}</div>
          <h4 style={{ textAlign: "center" }}>
            {daysRemaining} days Remaining
          </h4>
        </ModalHeader>
        <ModalBody>
          <div className="UpcomingPromotion-modal-body">
            <p className="UpcomingPromotion-modal-body">
              {promotionDescription}
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn--primary UpcomingPromotion-modal-button"
            onClick={toggle}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default UpcomingPromotionModal;
