import "../styles/layout.css";
import { Header } from "./Header";
import Footer from "./Footer";

export default function Layout({ children }) {
    return (
        <div>
            <Header />
            <main id="wrapper" role="main">
                <div className="main-padding">{children}</div>
            </main>
            <div className="footer">
                <Footer />
            </div>
        </div>
    );
}
