import QuantumMetrics from "./QuantumMetrics";

const RewardsOffline = () => {
  return (
    <>
      <div className="container">
        <form>
          <div className="row">
            <div className="hidden-sm col-md-4">
              <img src="/images/geckoPose.png" alt="Gecko" />
            </div>
            <div className="col-sm-12 col-md-8">
              <div className="encounter-error-message">
                <div className="section-header">
                  <div className="section-header-title-subtext padding-top">
                    <h3 className="font--bold">Access Not Allowed</h3>
                    <br />
                    <p>
                      <span>
                        The GEICO Rewards application is currently not
                        available.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <QuantumMetrics />
    </>
  );
};
export default RewardsOffline;
