import "../styles/ProgressBar.css";
import "../styles/Home.css";
import gift from "../images/presentBlue.png";
import React, { useEffect, useState, useContext } from "react";
import { FeatureFlagContext } from "../context/FeatureFlagContextProvider";

const addColumn = (index, max, filled) => {
  for (let [i, row] of [
    ...document.querySelectorAll("#progress-bar tr"),
  ].entries()) {
    const column = document.createElement(i ? "td" : "th");
    column.classList.add("progress-segment");
    const div = document.createElement("div");
    if (index === 0) {
      div.classList.add("left");
    }
    if (index === max - 1) {
      div.classList.add("right");
    }
    if (filled) {
      div.classList.add("progress-fill");
    } else {
      div.classList.add("progress-empty");
    }
    column.appendChild(div);
    row.appendChild(column);
  }
};

const ProgressBar = ({ goal, progress, payout, promotionType }) => {
  const featureFlags = useContext(FeatureFlagContext);
  const [promotion, setPromotion] = useState("event");
  const [promotionPlural, setPromotionPlural] = useState("events");
  const [promotionVerb, setPromotionVerb] = useState("Complete");
  const [promotionPastTense, setPromotionPastTense] = useState("completed");
  const [eventsRemainingToGoal, setEventsRemainingToGoal] = useState(null);
  const [figmaHeaderFlag, setFigmaHeaderFlag] = useState(false);
  const sales = "NEWBUSINESS";
  const quotes = "QUOTES";

  const quoteDictionary = {
    promo: "quote",
    plural: "quotes",
    verb: "Complete",
    pastTense: "completed",
  };

  const salesDictionary = {
    promo: "policy",
    plural: "policies",
    verb: "Sell",
    pastTense: "sold",
  };

  useEffect(() => {
    setFigmaHeaderFlag(featureFlags?.UpdatedHeaderOnHomePageFlag);
  }, [featureFlags]);

  useEffect(() => {
    for (let [, row] of [
      ...document.querySelectorAll("#progress-bar tr"),
    ].entries()) {
      if (row.hasChildNodes()) {
        while (row.firstChild) {
          row.firstChild.remove();
        }
      }
    }
    for (let i = 0; i < goal; i++) {
      if (i < progress) {
        addColumn(i, goal, true);
      } else {
        addColumn(i, goal, false);
      }
    }

    setEventsRemainingToGoal(goal - progress);

    switch (promotionType.toUpperCase()) {
      case quotes:
        setPromotion(quoteDictionary.promo);
        setPromotionVerb(quoteDictionary.verb);
        setPromotionPlural(quoteDictionary.plural);
        setPromotionPastTense(quoteDictionary.pastTense);
        break;
      case sales:
        setPromotion(salesDictionary.promo);
        setPromotionVerb(salesDictionary.verb);
        setPromotionPlural(salesDictionary.plural);
        setPromotionPastTense(salesDictionary.pastTense);
        break;
      default:
        setPromotion("events");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goal, progress, promotionType, promotion]);

  return (
    <>
      {!figmaHeaderFlag ? (
        <div>
          <h5>
            You have {promotionPastTense} {progress} out of {goal}{" "}
            {promotionPlural}.
          </h5>
          <div className="progress-bar">
            <h4 style={{ textAlign: "center" }}>
              Next Payout:{" "}
              <span className="money-green">${payout.toLocaleString()}</span>
            </h4>
            <table className="progress-width" id="progress-bar">
              <thead>
                <tr></tr>
              </thead>
            </table>
            <div className="progress-text" data-testid="goal">
              <p>
                {goal} {goal === 1 ? promotion : promotionPlural}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="spif-active-area">
            <div className="flex-box-img-text">
              <img src={gift} alt="gift"></img>
              <div className="progress-text-div">
                <h4 className="progress-text-lines">
                  You have {promotionPastTense} {progress}{" "}
                  {progress === 1 ? promotion : promotionPlural}.
                </h4>
                <span className="progress-text-lines">
                  {promotionVerb} {eventsRemainingToGoal} more{" "}
                  {eventsRemainingToGoal === 1 ? promotion : promotionPlural} to
                  reach your next milestone.
                </span>
              </div>
            </div>
            <div className="progress-bar-figma">
              <p style={{ textAlign: "right", margin: "0" }}>
                <strong>${payout.toLocaleString()}</strong>
              </p>
              <table className="progress-width" id="progress-bar">
                <thead>
                  <tr></tr>
                </thead>
              </table>
              <div className="progress-text" data-testid="goal">
                <p>
                  {goal} {goal === 1 ? promotion : promotionPlural}
                </p>
              </div>
            </div>
          </div>
          <div className="disclaimer">
            <div>
              {promotion === salesDictionary.promo ? (
                <p className="small-text">
                  This is a campaign where you sell more and get more.
                </p>
              ) : (
                <p className="small-text">
                  This is a campaign where you quote more and get more.
                </p>
              )}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                id="alternative-trigger-1"
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  //toggle();
                }}
              >
                Learn More
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default ProgressBar;
