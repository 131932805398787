import { useState, useEffect, useContext } from "react";
import { Wheel } from "react-custom-roulette";
import "../styles/wheel.css";
import * as rewardsApi from "../services/ApiServices/ApiService";
import { logError } from "../services/ApiServices/LoggingService";
import GameModal from "./GameModal";
import WheelTick from "../sounds/wheel-tick-v2.wav";
import QuantumMetrics from "./QuantumMetrics";
import { FeatureFlagContext } from "../context/FeatureFlagContextProvider";
import gift from "../images/presentBlue.png";

const pointer = {
  src: "/images/pointer.png",
  style: {
    transform: "rotate(-11deg) scale(1.15)",
    margin: "15px",
  },
};

const PrizeWheel = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [numberOfSpins, setNumberOfSpins] = useState(props.numberOfSpins);
  const [prizeWheelSoundFlag, setPrizeWheelSoundFlag] = useState(false);
  const [muteWheelSound, setMuteWheelSound] = useState(false);
  const [gameOnHomePageFlag, setGameOnHomePageFlag] = useState(false);
  const featureFlags = useContext(FeatureFlagContext);
  const incentiveId = props.incentiveId;
  const data = props.payoutOptions;

  useEffect(() => {
    setPrizeWheelSoundFlag(featureFlags?.PrizeWheelSoundFlag);
    setGameOnHomePageFlag(featureFlags?.PrizeWheelOnHomePageFlag);
  }, [featureFlags]);

  useEffect(() => {
    setNumberOfSpins(props.numberOfSpins);
  }, [props.numberOfSpins]);

  const getPayoutToken = async (incentiveId) => {
    let apiCallSuccess = false;
    let payoutResult;

    const handleError = (error) => {
      logError(error);
    };

    const handleSuccess = (responseData) => {
      apiCallSuccess = true;
      if (responseData.status === "Failure" || responseData.status === 500) {
        window.location.href = "/error";
      } else {
        payoutResult = responseData.response.payoutAmount;
      }
    };

    await rewardsApi.getPayoutToken(incentiveId, handleSuccess, handleError);
    if (apiCallSuccess) {
      return payoutResult;
    }
  };

  const [processingData, setProcessingData] = useState(false);

  const handleSpinClick = async () => {
    const audio = new Audio(WheelTick);

    setProcessingData(true);
    if (!mustSpin) {
      const payoutAmount = await getPayoutToken(incentiveId);
      setPrizeNumber(
        data
          .map(function (o) {
            return o.option;
          })
          .indexOf("$" + payoutAmount)
      );

      setNumberOfSpins(numberOfSpins - 1);
      setModalVisible(false);
      setMustSpin(true);

      if (prizeWheelSoundFlag && !muteWheelSound) {
        audio.play();
      }
    }
  };

  return (
    <>
      {!gameOnHomePageFlag && (
        <div>
          {numberOfSpins === 1 ? (
            <h4 data-testid="prize-wheel-spins-left-test">
              You have <span className="money-green">{numberOfSpins}</span> spin
              remaining.
            </h4>
          ) : (
            <h4 data-testid="prize-wheel-spins-left-test">
              You have <span className="money-green">{numberOfSpins}</span>{" "}
              spins remaining.
            </h4>
          )}
          <br />
          <br />
        </div>
      )}
      <div className="game-segment">
        <div className="wheel">
          <div className="wheel-base" data-testid="spinner-wheel-base">
            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              spinDuration={0.7}
              data={data}
              radiusLineColor="white"
              radiusLineWidth={7}
              outerBorderWidth={2}
              outerBorderColor="white"
              perpendicularText={false}
              fontSize={28}
              textDistance={75}
              textColors={["white"]}
              backgroundColors={["#1D4A96", "#573E94", "#1D4A96", "#9380C7"]}
              pointerProps={pointer}
              onStopSpinning={() => {
                setMustSpin(false);
                setTimeout(() => {
                  setModalVisible(true);
                  setProcessingData(false);
                }, 800);
              }}
            />
          </div>
          <div className="prize-wheel-button">
            <div
              className="inner-circle-small"
              onClick={handleSpinClick}
              disabled={processingData}
            >
              <div
                className="button-text"
                data-testid="prize-wheel-button-test"
              >
                <span className="button-text-top">Push to</span>
                <span className="button-text-bottom">Spin!</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {prizeWheelSoundFlag && (
        <div className="mute-button">
          <span
            onClick={() => {
              setMuteWheelSound(!muteWheelSound);
            }}
          >
            {muteWheelSound ? (
              <span
                aria-label="Unmute wheel noise"
                className="geico-icon geico-icon--actionable icon-muted-speaker"
              ></span>
            ) : (
              <span
                aria-label="Mute wheel noise"
                className="geico-icon geico-icon--actionable icon-speaker"
              ></span>
            )}
          </span>
        </div>
      )}
      {gameOnHomePageFlag && (
        <>
          <div>
            {numberOfSpins === 1 ? (
              <div>
                <img src={gift} alt="gift"></img>
                <h4
                  className="spin-text"
                  data-testid="prize-wheel-spins-left-home-test"
                >
                  You have 1 spin remaining.
                </h4>
              </div>
            ) : (
              <div>
                <img src={gift} alt="gift"></img>
                <h4
                  className="spin-text"
                  data-testid="prize-wheel-spins-left-home-test"
                >
                  You have {numberOfSpins + " "} spins remaining.
                </h4>
              </div>
            )}
          </div>
          <div className="disclaimer">
            <div>
              <p className="small-text">
                This is a campaign where you spin the wheel.
              </p>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                id="alternative-trigger-1"
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  //toggle();
                }}
              >
                Learn More
              </a>
            </div>
          </div>
        </>
      )}
      {modalVisible === true && numberOfSpins >= 0 && !gameOnHomePageFlag && (
        <GameModal
          prizeAmount={data[prizeNumber].option}
          numberOfSpins={numberOfSpins}
        />
      )}
      <QuantumMetrics />
    </>
  );
};

export default PrizeWheel;
