import { useState, useEffect } from "react";
import UpcomingPromotionCard from "./UpcomingPromotionCard";
import * as rewardsApi from "../../services/ApiServices/ApiService";
import { logError } from "../../services/ApiServices/LoggingService";
import { getDaysRemaining } from "../../helpers/incentiveHelper";
import "@gdk/card-selections/dist/styles/gdk-card-selections.css";

const UpcomingPromotions = () => {
  const [availableIncentives, setAvailableIncentives] = useState(null);

  const getAvailableIncentives = async () => {
    const handleError = (error) => {
      logError(error);
    };
    const handleSuccess = (responseData) => {
      setAvailableIncentives(responseData.response);
    };
    await rewardsApi.getAvailableIncentives(handleSuccess, handleError);
  };

  useEffect(() => {
    getAvailableIncentives();
  }, []);

  return (
    <div id="upcomingSection" className="row">
      {availableIncentives && availableIncentives.length > 0 && (
        <div>
          <h4 className="title-padding">Upcoming Promotions</h4>
          <div className="row">
            {availableIncentives.map(function (data, index) {
              return (
                <div className="col-sm-6" key={data.id}>
                  <UpcomingPromotionCard
                    id={data.id}
                    cardTitle={data.name}
                    promotionDescription={data.description}
                    daysRemaining={getDaysRemaining(data.enrollmentEndDate)}
                    index={index}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default UpcomingPromotions;
