import "../../styles/gdk";

const ExemptInput = () => {
  return (
    <div tabIndex="0">
      • Generally, individuals (including sole proprietors) are not
      exempt from backup withholding.
      <br />
      <br />
      • Except as provided below, corporations are exempt from
      backup withholding for certain payments, including interest
      and dividends.
      <br />
      <br />
      • Corporations are not exempt from backup withholding for
      payments made in settlement of payment card or third-party
      network transactions.
      <br />
      <br />
      • Corporations are not exempt from backup withholding with
      respect to attorneys’ fees or gross proceeds paid to
      attorneys, and corporations that provide medical or health
      care services are not exempt with respect to payments
      reportable on Form 1099-MISC.
      <br />
      <br />
      The following codes identify payees that are exempt from
      backup withholding. Select the appropriate code from the drop
      down.
      <br />
      <br />
      1—An organization exempt from tax under section 501(a), any
      IRA, or a custodial account under section 403(b)(7) if the
      account satisfies the requirements of section 401(f)(2).
      <br />
      <br />
      2—The United States or any of its agencies or
      instrumentalities.
      <br />
      <br />
      3—A state, the District of Columbia, a U.S. commonwealth or
      territory, or any of their political subdivisions or
      instrumentalities.
      <br />
      <br />
      4—A foreign government or any of its political subdivisions,
      agencies, or instrumentalities.
      <br />
      <br />
      5—A corporation.
      <br />
      <br />
      6—A dealer in securities or commodities required to register
      in the United States, the District of Columbia, or a U.S.
      commonwealth or territory.
      <br />
      <br />
      7—A futures commission merchant registered with the Commodity
      Futures Trading Commission.
      <br />
      <br />
      8—A real estate investment trust.
      <br />
      <br />
      9—An entity registered at all times during the tax year under
      the Investment Company Act of 1940.
      <br />
      <br />
      10—A common trust fund operated by a bank under section
      584(a).
      <br />
      <br />
      11—A financial institution as defined under section 581.
      <br />
      <br />
      12—A middleman known in the investment community as a nominee
      or custodian.
      <br />
      <br />
      13—A trust exempt from tax under section 664 or described in
      section 4947.
    </div>
  );
};
export default ExemptInput;
