import PrizeWheel from "./PrizeWheel";
import "../styles/Game.css";
import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext, UserIncentiveSummaryContext } from "../context/userContext";
import { FeatureFlagContext } from "../context/FeatureFlagContextProvider";
import { BANKING_ACCOUNT_NEEDS_UPDATING } from "../utils/constants";
import QuantumMetrics from "./QuantumMetrics";

const Game = () => {
    const location = useLocation(); 
    const [numberOfSpins, setNumberOfSpins] = useState();
    const [gameOnHomePage, setGameOnHomePage] = useState(false);
    const userIncentiveSummary = useContext(UserIncentiveSummaryContext);
    const user = useContext(UserContext);
    const featureFlags = useContext(FeatureFlagContext);
    const navigate = useNavigate();

  // Placeholder values necessary in payoutOptions for this component to be rendered
  const [payoutOptions, setPayoutOptions] = useState([
    { option: "$1" },
    { option: "$2" },
  ]);

  useEffect(() => {
    for (let i = 0; i < userIncentiveSummary?.length; i++) {
      if (userIncentiveSummary[i].incentiveId === location.state.chosenIncentiveId) {
        let wheelOptions = [];
        let payouts = userIncentiveSummary[i].gamificationPayoutOptions.map(
          (option) => option.payout
        );

        payouts.forEach((element) =>
          wheelOptions.push({ option: "$" + element.toLocaleString() })
        );

        setPayoutOptions(wheelOptions);
        setNumberOfSpins(userIncentiveSummary[i].gamificationTokens.tokensAvailable);
        break;
      }
    }
  }, []);

  useEffect(() => {
    if (
      user?.enrollmentStatus?.bankingStatus === BANKING_ACCOUNT_NEEDS_UPDATING
    ) {
      navigate("/onboarding", { state: { editMode: true } });
    }
    setGameOnHomePage(featureFlags?.PrizeWheelOnHomePageFlag);
  }, []);

  return (
    <>
      <div>
        {numberOfSpins > 0 && !gameOnHomePage && (
          <>
            <div className="flex-container">
              <h2 className="header-main">
                Congratulations! <br />
                Spin the wheel for a chance to win.
              </h2>
              <div className="wheel-padding">
                <PrizeWheel
                  numberOfSpins={numberOfSpins}
                  payoutOptions={payoutOptions}
                  incentiveId={location.state.chosenIncentiveId}
                />
              </div>
            </div>
            <div className="flex-container" style={{ marginTop: "5rem" }}>
              <button id="game-return-home" className="btn btn--secondary " onClick={() => {navigate("/home");}}>
                Return Home
              </button>
            </div>
          </>
        )}
      </div>
      <QuantumMetrics />
    </>
  );
};
export default Game;
