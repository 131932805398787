import { GdkTooltip } from "@gdk/tooltip";
import { useEffect, useRef } from "react";
import { GdkSocialSecurityNumber } from "@gdk/social-security-number";
import "../../styles/gdk";
import "@gdk/base/dist/js/jquery.maskedinput.geico";
import { addError, removeError } from "../../helpers/validationHelper";

export const ssnValidate = () => {
  let ssnValue = document.getElementById("ssn-input-value").value;
  if (ssnValue.length !== 11 || ssnValue.includes("_")) {
    addError(
      document.getElementById("ssn-form"),
      "Please enter a valid Social Security Number"
    );
    return false;
  }
  removeError(document.getElementById("ssn-form"));
  removeError(document.getElementById("fein-form"));
  return true;
};

const SsnInput = ({ lock = false }) => {
  var ssn = useRef();
  var tooltipSsn = useRef();
  useEffect(() => {
    ssn.current = new GdkSocialSecurityNumber({
      content: "#ssn",
    });
    tooltipSsn.current = new GdkTooltip({
      content: "#tooltip-trigger-ssn",
    });
    document.getElementById("ssn-input-field").onchange = () => {
      removeError(document.getElementById("ssn-form"));
      removeError(document.getElementById("fein-form"));
    };
    if (lock) {
      document.getElementById("ssn-input-field").disabled = true;
      document.getElementById("ssn-input-value").disabled = true;
    } else {
      document.getElementById("ssn-input-field").disabled = false;
      document.getElementById("ssn-input-value").disabled = false;
    }
  }, [lock]);

  return (
    <div>
      <div id="ssn">
        <div
          id="ssn-form"
          className="social-security-number-wrapper form-field"
        >
          <div className="tooltip-label-container">
            <label htmlFor="ssn-input-field">Social Security Number</label>
            <div className="tooltip-container">
              <a
                href="/#"
                id="tooltip-trigger-ssn"
                className="tooltip-trigger"
                data-tooltip-view="tooltip-ssn"
                aria-labelledby="input-label-id-5"
                tabIndex="-1"
              >
                ?
              </a>
              <div id="tooltip-ssn" className="tooltip">
                <div tabIndex="0">
                  At GEICO, we understand the importance of your privacy and
                  security. The information you provide will be kept
                  confidential and secure. It will not be sold or redistributed.
                  To learn more about how we protect your personal information,
                  please review our privacy &amp; security policy:&nbsp;
                  <a
                    href="https://media.geico.com/legal/privacy_policy.htm"
                    className=""
                  >
                    Privacy Policy
                  </a>
                </div>
                <button
                  className="icon-close"
                  aria-label="Close tooltip"
                ></button>
              </div>
            </div>
          </div>
          <input
            data-testid="ssnTest"
            aria-invalid="true"
            id="ssn-input-field"
            name="ssn-input-field"
            type="tel"
            className="social-security-number"
            autoComplete="off"
            spellCheck="false"
            placeholder="___-__-____"
          />
          <input
            data-testid="ssnInputTest"
            type="password"
            id="ssn-input-value"
            name="ssn-input-value"
            className="secureInput"
            maxLength="11"
            autoComplete="off"
            spellCheck="false"
            aria-hidden="true"
          />
          <a className="social-security-show-hide" href="/#" tabIndex="-1">
            <span className="icon-hide" aria-label="Hide"></span>
          </a>
        </div>
      </div>
    </div>
  );
};
export default SsnInput;
