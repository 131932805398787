import React, { useState, useEffect } from "react";
import { UserPendingPromotionsContext } from "./userContext";
import { useNavigate } from "react-router-dom";
import * as rewardsApi from "../services/ApiServices/ApiService";
import { logError } from "../services/ApiServices/LoggingService";

const UserPendingPromotionsContextProvider = ({ children }) => {
    const [userPendingPromotions, setUserPendingPromotions] = useState(null);
    const navigate = useNavigate();
    
    useEffect(() => {
        var location = window.location.pathname;

        if (location == "/home") {
            getUserPendingPromotions();
        }
        
        async  function getUserPendingPromotions() {
            const handleError = (error) => {
                logError(error);
            };
            const handleSuccess = (responseData) => {
                setUserPendingPromotions(responseData.response);
            };
            await rewardsApi.getPendingIncentives(handleSuccess, handleError);
        }
    }, [navigate]);

    return <UserPendingPromotionsContext.Provider value={userPendingPromotions}>{children}</UserPendingPromotionsContext.Provider>;
};

export default UserPendingPromotionsContextProvider;