import axios from "axios";

const logError = ({ response }) => {
    var errorLogInfo =
    {
        Message: JSON.stringify(response)
    };
    axios.post('/api/Logging/WriteErrorLog', errorLogInfo, { headers: { "Content-Type": "application/json" } });
};  

export { logError};