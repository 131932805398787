import { Link } from "react-router-dom"; 
import QunatumMetrics from "./QuantumMetrics";

const TermsOfUse = () => {
  return (
    <>
    <div className="container">
      <div className="header">
        <h2> Terms and Conditions of Website Use</h2>
      </div>
      <div className="content">
        <p>
          This website is part of GEICO’s Security and Privacy addendum (under
          you or your Agency’s Producer Agreement with GEICO). Your agency’s
          contract with GEICO incorporates GEICO’s Security and Privacy
          addendum. You acknowledge that your agency has provided you GEICO’s
          Security and Privacy addendum or otherwise advised you of its
          contents, and you agree to abide by its terms. By accessing GEICO’s
          Information Systems you will have exposure to GEICO’s Confidential
          Information (as that term is defined in the contract between your
          agency and GEICO). You acknowledge that your agency has advised you of
          its obligations with respect to GEICO’s Confidential Information and
          you agree to fulfill those obligations. The material provided on this
          website, including the information and any images incorporated in the
          site, is for your use only as a GEICO producer only. You may not
          modify, republish, post or transmit anything you obtain from this
          website, including anything you download from the site, unless you
          first obtain our consent. You agree not to engage in systematic
          retrieval of data or other content from this website. We request that
          you not create any kind of hyperlink from any other site to ours
          unless you first obtain our permission.
        </p>
        <p>
          <strong>
            It is very important that you read the next two sections carefully.
          </strong>
        </p>
        <p>
          <strong>LIMITATION OF LIABILITY</strong>
        </p>
        <p>
          <strong>
            GEICO WILL NOT BE RESPONSIBLE FOR ANY DAMAGES OR INJURIES THAT
            ACCOMPANY OR RESULT FROM YOUR USE OF ITS SITES. THESE INCLUDE (BUT
            ARE NOT LIMITED TO) DAMAGES OR INJURY CAUSED BY ANY: (1) USE OF (OR
            INABILITY TO USE) THE SITES; (2) USE OF (OR INABILITY TO USE) ANY
            SITE TO WHICH YOU HYPERLINK FROM ITS WEBSITES; (3) FAILURE OF
            PERFORMANCE; (4) ERROR; (5) OMISSION; (6) INTERRUPTION; (7) DEFECT;
            (8) DELAY IN OPERATION OR TRANSMISSION; (9) COMPUTER VIRUS; OR (10)
            LINE FAILURE. WE ARE NOT LIABLE FOR ANY CONSEQUENTIAL OR INCIDENTAL
            DAMAGES, WHICH ARE DAMAGES INTENDED TO COMPENSATE SOMEONE DIRECTLY
            FOR A LOSS OR INJURY, DAMAGES REASONABLY EXPECTED TO RESULT FROM A
            LOSS OR INJURY, OR OTHER MISCELLANEOUS DAMAGES AND EXPENSES
            RESULTING DIRECTLY FROM A LOSS OR INJURY. FURTHERMORE, EXCEPT AS
            PROVIDED BELOW, WE ARE NOT LIABLE EVEN IF WE HAVE BEEN NEGLIGENT OR
            IF OUR AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY
            OF SUCH DAMAGES—OR BOTH.
          </strong>
        </p>
        <p>
          <strong>DISCLAIMER</strong>
          <br />
          <strong>
            THE MATERIAL ON OUR SITES IS PROVIDED AS IS. THIS MEANS THAT GEICO
            DISCLAIMS ALL EXPRESS AND IMPLIED WARRANTIES ABOUT THE MATERIAL IN
            THE SITES. THIS INCLUDES, BUT IT IS NOT LIMITED TO, WARRANTIES THAT:
            (1) THE MATERIAL IS OF ANY PARTICULAR LEVEL OF QUALITY OR IS FIT FOR
            A PARTICULAR PURPOSE; (2) THAT THE FUNCTIONAL ELEMENTS CONTAINED IN
            THE MATERIALS WILL BE UNINTERRUPTED OR ERROR-FREE; (3) THAT DEFECTS
            WILL BE CORRECTED; (4) THAT OUR SITES, OR THE SERVERS THAT MAKE THEM
            AVAILABLE ARE FREE OF COMPUTER VIRUSES OR OTHER HARMFUL COMPONENTS
            OR CONDITIONS; OR (4) THAT INFORMATION CONTAINED IN THE SITES IS
            ACCURATE AS OF ANY PARTICULAR DATE.
          </strong>
        </p>
        <p>
          We sometimes provide access to other World Wide Websites from our
          sites or you may have reached our site from another site. However, we
          do not endorse or approve any products or information offered to you
          at sites you may reach from our sites or from which you may have
          reached our site. The Uniform Resource Locator (URL) address in your
          web browser will tell you if you are still in the GEICO site or have
          moved elsewhere.
        </p>
        <p>
          By providing material on our website GEICO does not in any way promise
          that the materials will remain available to you or that you will
          qualify for the products we offer. GEICO is entitled to terminate all
          or part of any of its Websites at any time, without notice to you.
        </p>
        <p>
          When you select a password and sign-up in order to access GEICO's
          online services, you agree to the following conditions:
        </p>
        <p>
          GEICO is authorized to act on instructions received under your
          password without any requirement to question those instructions;
        </p>
        <p>
          <ul>
            <li>
              GEICO is not liable for any unauthorized access to your personal
              information that is not directly due to the negligence of GEICO;
            </li>
            <li>
              Your password contains sensitive information and you will keep it
              confidential and secret;
            </li>
            <li>
              You will notify GEICO immediately if you believe anyone else has
              learned your password or if you believe an unauthorized access to
              GEICO or your personal information has occurred or may occur;
            </li>
            <li>
              GEICO may deny access or block any transaction made under your
              password without prior notice if we believe your password is being
              used by someone other than you, or if any unauthorized access to
              your personal information has occurred or may occur, or for any
              other reason, but we are under no obligation to do so.
            </li>
            <li>
              Violating the Terms and Conditions of Website Use may lead to a
              revocation of GEICO website access privileges.
            </li>
          </ul>
        </p>
        <p>
          <strong>PRIVACY</strong>
          <br />
          Some of the information on this site is "nonpublic personal
          information," protected under the federal Gramm-Leach-Bliley Act and
          state privacy laws. When using this website in connection with your
          representation of GEICO, you will adhere to GEICO’s&nbsp;
          {<Link to="/privacyPolicy">Privacy Policy</Link>}. When not
          representing GEICO, however, you must independently comply with
          applicable federal and state privacy laws with respect to your
          collection, use and disclosure of nonpublic personal information.
        </p>
        <p>
          <strong>ADDITIONAL INFORMATION FOR OUR WEBSITE USERS</strong>
          <br />
          We continuously improve geico.com. We are interested in how visitors
          use our website, what they like and dislike, and where they encounter
          problems. We may gather data on how users navigate our site so we can
          make geico.com an easier place to do business. We use various tools to
          gather data, such as what browser a person uses or even what pages are
          most popular. We may also use this data, in aggregated or anonymized
          form, to offer you content, products, or services we hope will
          interest you.
        </p>
        <p>
          We use cookies to improve your experience while using geico.com and
          our mobile apps. Cookies are small files that are stored on your
          device used to access our services. They help us personalize your
          experience when you return to our website, and they are required for
          certain portions or functionality of our website. Most browsers let
          you determine whether to accept our cookies. If you don't use cookies,
          you won't be able to use certain geico.com features, such as the
          "remember me" function for policyholders that lets you store your User
          ID so you don't have to input it every time you log in from the same
          computer. Please see your browser settings for more information about
          managing cookies.
        </p>
        <p>
          Geico.com may also place small pieces of computer code (embedded
          objects) on our pages to help our advertising partners count how many
          customers they referred to our site. We may use these objects to track
          how many referred customers actually get a rate quote. We may also use
          objects placed on other sites to monitor your exposure to our
          advertising or other offers online. These objects generally collect
          device identifiers, IP addresses, and similar identifiers, but do not
          collect things like your name or contact information. You can opt out
          of our advertising service provider(s) and other companies' tracking
          cookies used for interest-based ads by visiting the Digital
          Advertising Alliance's&nbsp;
          {
            <a href="https://www.aboutads.info/choices/" target="_blank">
              aboutads.info choices page
            </a>
          }
        </p>
        <p>
          We also provide some links to third-party websites that are not owned
          by GEICO. GEICO has no control over their privacy practices and
          assumes no responsibility in connection with your use of these
          third-party websites. We recommend that you check the privacy policy
          of any website before you use it or provide any information.
        </p>
        <p>
          <strong>JURISDICTION</strong>
          <br />
          Unless otherwise specified, the materials in our site are presented to
          provide information about the Government Employees Insurance Companies
          and their affiliates, (collectively "GEICO"), our insurance products
          and the insurance business. GEICO operates and controls its sites from
          the Company’s headquarters in Chevy Chase, Maryland, in the United
          States of America, and Maryland law shall govern and control any
          action concerning the GEICO sites. We do not in any way imply that the
          materials on the sites or the products we discuss are available or for
          use outside the United States or in jurisdictions in which we are not
          licensed to do business, or that we are soliciting business in any
          such jurisdiction. You will only request rate quotes for individuals
          that are residents of jurisdictions where GEICO is licensed.
        </p>
        <p>
          By using this website, you agree to these Terms and Conditions of
          Website Use.
        </p>
        <p>
          <strong>MISCELLANEOUS</strong>
          <br />
          These Terms and Conditions of Website Use, and the agreement they
          create, shall be governed by and interpreted according to the laws of
          the State of Maryland, without giving effect to Maryland’s conflict of
          laws principles.
        </p>
        <p>
          If any provision of this agreement is unlawful, void or unenforceable,
          it shall not affect the validity and enforceability of any remaining
          provisions.
        </p>
        <p>
          GEICO may modify these Terms and Conditions of Website Use, and the
          agreement they create, at any time, simply by updating this posting or
          the information on its website and without notice to you.
        </p>
        <p>
          Government Employees Insurance Company and its affiliates GEICO Marine
          Insurance Company, GEICO General Insurance Company, GEICO Indemnity
          Company, GEICO Casualty Company, GEICO Advantage Insurance Company,
          GEICO Choice Insurance Company, GEICO Secure Insurance Company,
          Colonial County Mutual Insurance Company (non-affiliated) and GEICO
          County Mutual Insurance Company insure private passenger automobiles
          and other vehicles, and through GEICO Insurance Agency, Inc. provide
          homeowner and other types of insurance, all for qualified applicants.
          The companies market collectively under the trade name GEICO.
        </p>
      </div>
    </div>
    <QunatumMetrics />
    </> 
  );
};

export default TermsOfUse;
